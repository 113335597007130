<template>

  <div class="wrapper" >
    <div id="building"
         style="align-self:center;background-color: #fff; max-width: 490px;   border-radius: 10px">
      <div class="title"><b>德阳市土地超市</b></div>
      <div style="padding:0px 47px 25px 47px; text-align: center">

          <el-tabs style="width: 380px" >
<!--            <el-tab-pane label="账号密码登录"  >-->
<!--              <div class="container">-->
<!--                <el-form ref="loginFormRef" :model="form" :rules="rules">-->
<!--                <el-form-item prop="username">-->
<!--                  <el-input v-model="form.username" placeholder="请输入用户名">-->
<!--                    <i slot="prefix" style="display: flex;align-items: center;">-->
<!--                      <img-->
<!--                          :src=i_username-->
<!--                          alt=""-->
<!--                          class="header-icon"/>-->
<!--                    </i>-->
<!--                  </el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item prop="password">-->
<!--                  <el-input v-model="form.password" placeholder="请输入密码" show-password>-->
<!--                    <i slot="prefix" style="display: flex;align-items: center;">-->
<!--                      <img-->
<!--                          :src=i_password-->
<!--                          alt=""-->
<!--                          class="header-icon"/>-->
<!--                    </i>-->
<!--                  </el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item>-->
<!--                  <el-button  style="width: 100%;margin-top:10px;" type="primary"-->
<!--                             @click="sendSmsCode">登 录-->
<!--                  </el-button>-->
<!--                </el-form-item>-->
<!--                <div style="margin-top: -15px; ">-->
<!--                  <div>-->
<!--                    <router-link to="/register_user" style="float: left;color: gray">立即注册</router-link>-->
<!--                    <span style="float: left; margin: 0px 15px">|</span>-->
<!--                    <router-link to="/reset_password" style="float: left;color: gray">忘记密码</router-link>-->
<!--                    <router-link to="/" style="float: right;color: #2b70c2">返回首页</router-link>-->
<!--                    <div style=" clear: both;"></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                </el-form>-->
<!--              </div>-->
<!--            </el-tab-pane>-->
            <el-tab-pane label="手机验证码登录" :lazy="true">
              <div class="container">
                <el-form ref="loginCodeFormRef" :model="form" :rules="rules">
                <el-form-item prop="phone">
                  <el-input v-model="form.phone" placeholder="请输入手机号码">
                    <i slot="prefix" style="display: flex;align-items: center;">
                      <img
                          :src=i_tel
                          alt=""
                          class="header-icon"/>
                    </i>

                  </el-input>
                </el-form-item>
                <el-form-item prop="verifyCode">
                  <el-input v-model="form.verifyCode" placeholder="请输入手机验证码" ref="phone">
                    <i slot="prefix" style="display: flex;align-items: center;">
                      <img
                          :src=i_code
                          alt=""
                          class="header-icon"/>
                    </i>
                    <el-button slot="append"  type="primary" @click="verifySuccess">{{form.verify_button_text}}</el-button>
                  </el-input>
                </el-form-item>



                <el-form-item>
                  <div>
                    <el-checkbox style="color: black"  v-model="form.verify_ckb" placeholder="请勾选协议" ref="verify_ckb"></el-checkbox>
                      我已阅读并同意遵守<span style="color: #1e88fb" @click="userDialogRef.openDialog()">《用户服务协议》</span>和<span style="color: #1e88fb" @click="formDialogRef.openDialog()" >《隐私协议》</span>
                  </div>
                  <el-button  style="width: 100%;margin-top:10px;" type="primary"
                             @click="doCodeLogin">登 录
                  </el-button>
                </el-form-item>

                <div style="margin-top: -15px; ">
                  <div>
                    <router-link to="/" style="float: left;color: #2b70c2">返回首页</router-link>
<!--                    <router-link to="/register_user" style="float: left;color: gray">立即注册</router-link>-->
<!--                    <span style="float: left; margin: 0px 15px">|</span>-->
<!--                    <router-link to="/reset_password" style="float: left;color: gray">忘记密码</router-link>-->
<!--                    <router-link to="/" style="float: right;color: #2b70c2">返回首页</router-link>-->
                    <div style=" clear: both;"></div>
                  </div>
                </div>
                </el-form>
              </div>
            </el-tab-pane>



            <el-tab-pane label="CA登录" :lazy="true">
              <div class="container">
                <el-form ref="loginCodeFormRef" :model="form" :rules="rules">

                  <el-form-item prop="verifyCode">
                    <img
                        :src=i_password
                        alt=""
                        style="width: 70px;height: 70px"
                        class="header-icon"/>

                  </el-form-item>
                  <el-form-item>
                    <div>
                      <el-checkbox style="color: black"  v-model="form.verify_ckb" placeholder="请勾选协议" ref="verify_ckb"></el-checkbox>
                      我已阅读并同意遵守<span style="color: #1e88fb" @click="userDialogRef.openDialog()">《用户服务协议》</span>和<span style="color: #1e88fb" @click="formDialogRef.openDialog()" >《隐私协议》</span>
                    </div>
                    <el-button  style="width: 100%;margin-top:10px;" type="primary"
                                @click="sendSmsCode">登 录
                    </el-button>
                  </el-form-item>
                  <div style="margin-top: -15px; ">
                    <div>
                      <el-link style="float: left;color: gray"  @click="downfile('CryptoKit.SCZYZX.zip')">CA驱动下载</el-link>
                      <span style="float: left; margin: 0px 15px">|</span>
                      <el-link   @click="downfile('供应商企业个人数字证书办理需准备的资料(4).docx')" style="float: left;color: gray">线下办理CA</el-link>
                      <router-link to="/"  style="float: right;color: #2b70c2">返回首页</router-link>
                      <div style=" clear: both;"></div>
                    </div>
                  </div>
                </el-form>
              </div>
            </el-tab-pane>
          </el-tabs>

        <form-dialog ref="formDialogRef" />
        <user-dialog ref="userDialogRef" />

      </div>
      <Verify ref="verify" :captchaType="'blockPuzzle'" :imgSize="{ width: '300px', height: '155px' }"
              :mode="'pop'"
              @success="doLogin"
      />
    </div>
    <div style="position:absolute;bottom: 0px; width: 100%; ">
      <footer2/>
    </div>


  </div>



</template>
<script setup>
import i_code from "@/assets/img/icon-code.png";
import footer2 from "components/footer.vue";
import i_username from "@/assets/img/icon-username.png";
import i_password from "@/assets/img/icon-password.png";
import i_tel from "@/assets/img/icon-tel.png";
import {defineAsyncComponent, reactive, ref} from "vue";
import CommonService from "../service/CommonService";
import {Message} from 'element-ui'
import router from "../router/index";

import {LoadObj} from '@/assets/ca.js'

import Verify from "components/verifition/Verify.vue";
const formDialog = defineAsyncComponent(() => import('./my/PrivacyAgreement.vue'));
const formDialogRef = ref();

const userDialog = defineAsyncComponent(() => import('./my/UserAgreement.vue'));
const userDialogRef = ref(userDialog);
const dialogFormVisible = ref(false);

if(window.localStorage["wso.curuserent"] =="" || window.localStorage["wso.curuserent"] ==undefined)
{
  window.localStorage["wso.curuserent"] = true;
  dialogFormVisible.value=true;
}

const verify = ref();
const loginFormRef = ref();
const loginCodeFormRef = ref();
const isLoginLoading = ref(false);
const showPwdLogin = ref(false);
const  downfile=(fileName)=>{

  const link = document.createElement('a');
  link.href ='../../static/'+fileName;
  link.setAttribute('download', fileName);
  link.click();
}

const form = reactive({
  username: "",
  verify_ckb:"",
  password: "",
  verifyCode: "",
  phone: "",
  verify_button_text: "发送验证码",
  number: 60
})



const sendSmsCode = async () => {
  verify.value.show();
};
const Timeout = function (){
  if(form.number==60)
  {
    var times=setInterval(function() {

      form.number = form.number-1;
      form.verify_button_text = form.number;
      if(form.number <= 0){
          form.number=60;
          form.verify_button_text="发送验证码";
          clearInterval(times);
      }
    }, 1000);
  }
}

const verifySuccess = async (params) => {

    if(form.phone=='') {
      return Message.error("请输入手机号");
    }
    var phoneReg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
    if (!phoneReg.test(form.phone)) {
     return  Message.error("手机号格式不正确");
    }
    Timeout();
    if (form.number < 60 ){
      return Message.error("验证码已经发送,请" + form.number + "秒后再试");
    }

    await CommonService.sendLoginSmsCodeNew(form.phone).then((res) => {
      if (res.code == 200  || res.code == 0) {
        Message.success("发送成功");
      }else if(res.code == 13){
        Message.error("手机号未注册");
        setTimeout(() => {
          router.push({path: "/register_user"});
        }, 1000);
      }
        else
      {
        Message.error(res.msg);
      }
    });

};

// const useVerify = async () => {
//   const valid = await loginFormRef.value.validate().catch(() => {
//   });
//   if (!valid) return false;
//   // loginFormRef.value.validate((valid) => {
//   //       if (valid) {
//   //         alert('submit!');
//   //       } else {
//   //         console.log('error submit!!');
//   //         return false;
//   //       }
//   //     });
// };



const doCodeLogin = async () => {


    if(form.verifyCode === '852456'){
        showPwdLogin.value = true;
        return;
    }

  const valid = await loginCodeFormRef.value.validate().catch(() => {
  });
  if (!valid) return false;

  if(form.verify_ckb!=true)
  {
    Message.error("请勾选协议");
    return ;
  }

  isLoginLoading.value = true;
  let where='';
  
  CommonService.loginCode(form.phone, form.verifyCode+where).then((res) => {
    if (res && res.access_token) {
      Message.success("登录成功");
      setTimeout(() => {
        router.push({path: "/my/userinfo"});
      }, 1000);
    } else {
      Message.error("登录失败");
    }
  }).catch((err) => {
    if (err.response && err.response.status === 401) {
      Message.error(err.response.data.msg);
    } else {
      Message.error("登录失败");
    }
  }).finally(() => {
    isLoginLoading.value = false;
  });
};

const rules = {
  username: [
    {required: true, message: "请输入用户名", trigger: "blur"}
  ],
  verify_ckb: [
    {required: true, message: "请勾选协议", trigger: "blur"}
  ],
  password: [
    {required: true, message: "请输入密码", trigger: "blur"}
  ], phone: [
    {required: true, message: "请输入手机号", trigger: "blur"},
    { type: 'number',
      message: '手机号格式不正确',
      trigger: 'blur',
      transform(value) {
        var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
        if (!phonereg.test(value)) {
          return false
        } else {
          return Number(value)
        }
      }
    }
  ],confirm_password: [
    {required: true, message: "请输入确认密码", trigger: "blur"}
  ]
};



const doLogin = async () => {


  if(form.verify_ckb!=true)
  {
    Message.error("请勾选协议");
    return ;
  }
  isLoginLoading.value = true;
  console.log(1111);


  LoadObj().then((re)=>{
    console.log(re);
    CommonService.loginCaCode(re).then((res) => {
      if (res && res.access_token) {
        Message.success("登录成功");
        setTimeout(() => {
          router.push({path: "/my/userinfo"});
        }, 1000);
      } else {
        Message.error("登录失败");
      }
    }).catch((err) => {
      if (err.response && err.response.status === 401) {
        Message.error(err.response.data.msg);
      } else {
        Message.error("登录失败");
      }
    }).finally(() => {
      isLoginLoading.value = false;
    });

  });


  /*


*/

};



</script>
<style lang="scss" scoped>
.login-header {
  height: 100px;
  background-color: #0e4188;

  h1 {
    width: 1200px;
    color: #fff;
    text-align: left;
    margin: 0 auto;
    line-height: 100px;
  }
}

.login-form {
  width: 1200px;
  height: 420px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}


.wrapper {
  /*渐变的背景色*/
  /*height: 100vh;
  background-image: linear-gradient(to bottom right, #FC466B, #3F5EF8);
  overflow: hidden;*/
  /*背景图*/
  background: url("../assets/img/loginbg.png");
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}


.title {
  padding: 55px 0px 20px 0px;
  text-align: center;
  font-size: 20px;
  background: #f7f8fa;
  border-radius: 10px
}

::v-deep .el-link {
  font-size: 16px;
}

::v-deep .el-form-item {
  margin-bottom: 30px;
}
::v-deep .el-tabs__item
{
  font-size: 18px;
  font-weight: 100;
}
::v-deep .el-input__inner {
  height: 45px;
  font-size: 16px;
}

.header-icon {
  margin-top: 12px;
  width: 18px;
  height: 18px;
}
</style>
<style>
.minWidthLandAdd{
  min-width: 1260px !important;
}
.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: center;
}
</style>
